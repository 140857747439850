/*eslint semi: ["error", "always"]*/
import React from 'react';
import './App.css';
import {
	MIN_PLAYERS, MAX_PLAYERS, START_NUM, Game
} from './game';
import Help from './Help';
import {
	assert,
	sound,
	tileName, tileNums, isTile, tileHasValue
} from './util';

/*
 * Images
 */
import trainImg from './image/train.svg';
import canadianImg from './image/mapleLeaf.svg';
import justinImg from './image/justin.jpg';

var game;					// contains the state of the current game set

/*
 * The main App component
 */
class App extends React.Component {
	constructor (props) {
		super(props);
		game = new Game(() => this.onChange(), () => this.onWinner());
		window.game = game;		// for debugging
		this.state = {
			stateNum: -1,
			showStats: false,
			showHelp: false,
		};
	}
	render () {
	  return (
		<div className="App">
			<Arena stateNum={game.stateNum}
				showStats={() => this.showStats(true)}
				showHelp={() => this.showHelp(true)}
			/>
			<Hand stateNum={game.stateNum} />
			<Message message={game.message} />
			<NewGame />
			{this.state.showStats
				&& <TrainStats onDone={() => this.showStats(false)}/>
			}
			{this.state.showHelp
				&& <Help onDone={() => this.showHelp(false)}/>
			}
		</div>
	  );
	}
	onChange () {
		this.setState({
			stateNum: game.stateNum,
		});
	}
	onWinner () {
		game.notice(
			<Winner gameOver={game.gameNum < 0}/>,
			() => {
				if (game.gameNum < 0) {
					// reset the game
					window.location.reload();
				}
			});
	}
	showStats (s) {
		this.setState({showStats:s});
	}
	showHelp (s) {
		this.setState({showHelp:s});
	}
}

/*
 * New game dialog.
 */
class NewGame extends React.Component {
	constructor (props) {
		const lowestStartNum = START_NUM.reduce((a, n) => a && n ? Math.min(a, n) : n);
		super(props);
		this.state = {
			message: '',
			joinGame: true,
			gameId: '',
			playerName: '',
			playerNames: Array(MAX_PLAYERS).fill(''),
			maxRounds: 1,
			rounds: 1,
		};
	}
	render () {
		if (game.message || game.gameNum >= 0) {
			return (null);
		}
		const renderNewGame = (() => {
			const playerNames = this.state.playerNames;
			const renderRounds = () => {
				const options = [];
				for (let i = this.state.maxRounds; i >= 1; i--) {
					options.push(<option value={String(i)}>{i}</option>);
				}
				return (options);
			};
			return (
				<div className="newGameBox">
					<table>
						<thead>
							<tr><th>Enter 2 to 6 player names</th></tr>
						</thead>
						<tbody>
							{playerNames.map((p, i) =>
								<tr key={i}>
									<td>
										<input type="text"
											placeholder={i === 0
												? `Your name`
												: `Player ${String(i + 1)} name`
											}
											value={playerNames[i]}
											onChange={(ev) =>
												this.handlePlayerChange(ev, i)
											}
										/>
									</td>
								</tr>
							)}
						</tbody>
					</table>
					<div>
						Game rounds: <select
							value={this.state.rounds}
							onChange={(ev) => this.setState({rounds:Number(ev.target.value)})}
						>
						{renderRounds()}				
						</select>
					</div>
					<div className="buttonArea">
						<button onClick={() => this.startGame()}>Start game</button>
						<button onClick={() => this.switchTo('join')}>Cancel</button>
					</div>
				</div>
			);
		});
		const renderJoinGame = (() => {
			return (
				<div className="newGameBox">
					<div>
						<table><tbody>
							<tr>
								<td>Player name:</td>
								<td>
									<input
										value={this.state.playerName}
										onChange={(ev) => this.handleJoinChange(ev, 'playerName')}
									/>
								</td>
							</tr>
							<tr>
								<td>Game ID:</td>
								<td>
									<input
										value={this.state.gameId}
										onChange={(ev) => this.handleJoinChange(ev, 'gameId')}
									/>
								</td>
							</tr>
						</tbody></table>
						<br />
						<button onClick={() => this.joinGame()}>Join game</button>
					</div>
					or
					<button onClick={() => this.switchTo('create')}>
						Create a new game
					</button>
				</div>
			);
		});
		const renderNotice = (() => {
			return (
				<div className="newGameBox">
					<div className="messageText"
						dangerouslySetInnerHTML={{__html: `${this.state.message}`}}
					/>
					<button onClick={() => this.setState({message:''})}>
						OK
					</button>
				</div>
			);
		});

		return (
			<div className="messageWrapper">
				{this.state.message
					? renderNotice()
					: (this.state.joinGame
						? renderJoinGame()
						: renderNewGame()
					)
				}
			</div>
		);
	}
	handlePlayerChange (ev, i) {
		let playerNames = this.state.playerNames;
		playerNames[i] = ev.target.value;
		const playerCount = playerNames.reduce((c, n) => n ? c + 1 : c, 0);
		const maxRounds = START_NUM[playerCount] + 1;
		this.setState({
			playerNames:playerNames,
			maxRounds: maxRounds,
			rounds: maxRounds,
		});
	}
	handleJoinChange (ev, elt) {
		let o = {};
		if (ev.target.value === 'XYZZY') {
			// magic value to enable test mode
			game.test = true;
			// erase it
			o[elt] = ev.target.value = '';
		} else {
			o[elt] = ev.target.value.trim();
		}
		this.setState(o);
	}
	joinGame () {
		if (!this.state.gameId || !this.state.playerName) {
			game.notice(`No player name and/or game ID`);
			return;
		}
		if (!game.joinGame(this.state.gameId, this.state.playerName, (ok, msg) => {
			if (!ok) {
				game.notice(`Can't join game ${this.state.gameId}
					as ${this.state.playerName} ${msg}`);
			} else {
				sound.play('welcome');
			}
		})) {
			game.notice(`Can't join game ${this.state.gameId} as ${this.state.playerName}`);
		}
	}
	startGame () {
		let playerNames = this.state.playerNames.filter((name) => name);
		let rounds = this.state.rounds;
		if (playerNames.length >= MIN_PLAYERS) {
			game.createGame(playerNames, rounds, (ok, msg) => {
				if (ok) {
					game.notice(
						`Tell players to join game ID ${game.gameId} using their player name`
					);
				} else {
					game.notice(`Can't create game ${msg}`);
				}
			});
		}
	}
	switchTo (mode) {
		this.setState({joinGame:(mode === 'join')});
	}
	notice (msg) {
		this.setState({message:msg});
	}
}

/*
 * Message dialog.
 */
class Message extends React.Component {
	render () {
		if (!this.props.message) {
			return (null);
		}
		return (
			<div className="messageWrapper">
				<div className="messageBox">
					{typeof (this.props.message) === 'string'
						? <div className="messageText"
							dangerouslySetInnerHTML={{__html: `${this.props.message}`}}
						/>
						: this.props.message
					}
					<div className="buttonArea">
						<button onClick={() => game.noticeDone()}>OK</button>
					</div>
				</div>
			</div>
		);
	}
}

/*
 * The arena. Contains the initial tile and trains.
 */
class Arena extends React.Component {
	render () {
		if (game.gameNum < 0) {
			return (<div className="arena"></div>);
		}
		return (
			<div className="arena">
				<div className="arenaHeader">
					<div>
						<button className="trainStats"
							onClick={() => this.props.showStats()}
						>
							Train stats
						</button>
					</div>
					<div>
						<table className="gameText"><tbody>
							<tr><td>Game ID:</td><td>{game.gameId}</td></tr>
							<tr><td>Round:</td><td>{game.gameNum}</td></tr>
						</tbody></table>
						<InitialTile  />
					</div>
					<div>
						<button className="helpButton"
							onClick={() => this.props.showHelp()}
						>
							Help
						</button>
					</div>
				</div>
				<div className="arenaPlayers">
					<Player key="__Mexican__"
						stateNum={game.stateNum}
						playerName={""}
						playable={game.isMyTurn() && game.isPlayableTrain("")}
						publicTrain={true}
					/>
					{game.players.map(player =>
						<Player key={player.name}
							stateNum={game.stateNum}
							playerName={player.name}
							playable={game.isMyTurn()
								&& game.isPlayableTrain(player ? player.name : '')}
							publicTrain={player.public}
						/>
					)}
				</div>
			</div>
		);
	}
}

/*
 * The initial tile.
 */
class InitialTile extends React.Component {
	render () {
		if (game.hasInitialTile) {
			return (
				<Tile stateNum={game.stateNum} className="initalTile"
					value={tileName(game.gameNum)}
					orientation="H" />
			);
		}
		return (
			<Tile stateNum={game.stateNum} className="initalTile" value="" orientation="H"
				playable="true"
				onDragOver={(ev) => this.handleDragOver(ev)}
				onDrop={(ev) => this.handleDrop(ev)}
				onClick={(ev) => this.handleClick(ev)}
			/>
		);
	}
	handleDragOver (ev) {
		if (!game.hasInitialTile) {
			ev.preventDefault();
		}
	}
	handleDrop (ev) {
		let tile = ev.dataTransfer.getData("text/plain");
		assert(tile, "Player.handleDrop: no value");
		// only allow drop if value matches double gameNum.

		console.log(`InitialTile.handlDrop: ${tile}`);
		if (!game.hasInitialTile && tile && game.playTile(tile, '')) {
			console.log(`Drop ${tile} on initial tile`);
			sound.play('drop');
		} else {
			console.log(`Can't drop ${tile} on initial tile`);
			sound.play('fail');
		}
		game.selected = '';
	}
	handleClick (ev) {
		let tile = tileName(game.gameNum);
		if (!game.hasInitialTile && game.hasTileInHand(tile) && game.playTile(tile, '')) {
			console.log(`Drop ${tile} on initial tile`);
			sound.play('drop');
		} else {
			console.log(`Can't drop ${tile} on initial tile`);
			sound.play('fail');
		}
		game.selected = '';
	}
}

/*
 * A player. Contains some player info and the head of the player's train.
 * If props.playerName is empty, it the Mexican train.
 */
class Player extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			animate: 'no',
		};
	}
	componentDidMount () {
		game.showDrop.set(this.props.playerName, () => this.showDrop());
	}
	render () {
		let player = game.findPlayer(this.props.playerName);
		let train = (player ? player.train : game.mexTrain);
		return (
			<div
				className={"player"
					+" players"+String(game.players.length)
					+(player ? "" : " mtrain")
					+(player && game.players[game.curPlayerIndex] === player
						? " turn"
						: ""
					)
			}>
				<div className="playerText">
					{player
						? <React.Fragment>
							<div className="playerName">{player.name}</div>
							{player.joined
								? <table><tbody>
									<tr><td>Score:</td><td>{player.score}</td></tr>
									<tr className={(player.tiles === 1 ? "chap" : "")}>
										<td>Tiles left:</td>
										<td>
											{player.tiles}
										</td>
									</tr>
								</tbody></table>
								: <div>Has not joined game {game.gameId} yet</div>
							}
							{this.props.publicTrain
								? <div><img className="trainImg" src={trainImg} alt="train" /></div>
								: <div></div>
							}
						</React.Fragment>
						: <React.Fragment>
							<div className="playerName">Canadian Train</div>
							<div><img className="mTrainImg" src={canadianImg} alt="leaf" /></div>
						</React.Fragment>
				}
				</div>
				<div className="playerTrain">
					<div className={'wrapper '
						+(train.length > 1
							? (this.state.animate === 'no'
								? 'showTop1'
								: (this.state.animate === 'initial'
									? 'showTop2'
									: 'showTop1A'
								)
							)
							: (train.length === 0 ? 'showDrop' : 'showTop1')
						)
					}>
						<Tile stateNum={game.stateNum} value={train[1] ? train[1] : ''} />
						<Tile stateNum={game.stateNum} value={train[0] ? train[0] : ''} />
						<Tile stateNum={game.stateNum} value=""
							playable={this.props.playable}
							onDrop={(ev) => this.handleDrop(ev)}
							onClick={(ev) => this.handleClick(ev)}
							onDragOver={(ev) => this.handleDragOver(ev, player)}
						/>
					</div>
				</div>
			</div>
		);
	}
	handleDragOver (ev, player) {
		if (game.isMyTurn() && game.isPlayableTrain(player ? player.name : '')) {
			ev.preventDefault();
		}
	}
	handleDrop (ev) {
		let player = game.findPlayer(this.props.playerName);
		let tile = ev.dataTransfer.getData("text/plain");
		let trainName = (player ? player.name : '');
		assert(tile, "Player.handleDrop: no value");
		if (game.drawnTile && tile !== game.drawnTile) {
			game.notice("Move previously drawn tile to hand before playing from hand.");
			console.log(`Can't drop from hand with drawn tile`);
			sound.play('fail');
			return;
		}
		if (game.playTile(tile, trainName)) {
			console.log(`Drop ${tile} on train ${trainName ? trainName : 'mexTrain'}`);
			sound.play('drop');
			this.showDrop();
		} else {
			console.log(`Can't drop ${tile} on train ${trainName ? trainName : 'mexTrain'}`);
			sound.play('fail');
		}
	}
	handleClick (ev) {
		let player = game.findPlayer(this.props.playerName);
		let trainName = (player ? player.name : '');
		let tile = '';
		if (game.selected) {
			tile = game.selected;
		} else if (game.drawnTile) {
			tile = game.drawnTile;
		} else {
			let tiles = game.layout.filter(t => game.isPlayableTile(t));
			if (tiles.length === 1) {
				tile = tiles[0];
			}
		}
		if (tile && game.playTile(tile, trainName)) {
			console.log(`Drop ${tile} on train ${trainName ? trainName : 'mexTrain'}`);
			sound.play('drop');
			this.showDrop();
			game.selected = '';
		} else {
			console.log(`Can't drop ${tile} on train ${trainName ? trainName : 'mexTrain'}`);
			sound.play('fail');
		}
	}
	showDrop () {
		let player = game.findPlayer(this.props.playerName);
		let train = (player ? player.train : game.mexTrain);
		if (train.length < 2) {
			this.setState({animate: 'no'});
		} else {
			this.setState({animate: 'initial'});
			setTimeout(() => this.setState({animate: 'final'}), 500);
		}
	}
}

/*
 * Popup message giving train stats.
 */
class TrainStats extends React.Component {
	render () {
		let trainTiles = [tileName(game.gameNum), ...game.mexTrain];
		game.players.forEach((p) => {
			trainTiles = [...trainTiles, ...p.train];
		});
		let tileCount = [];
		let doubles = [];
		for (let i = 0; i <= game.setSize; i++) {
			let tiles = trainTiles.filter((tile) => tileHasValue(tile, i));
			tileCount[i] = tiles.length;
			doubles[i] = tiles.some((tile) => isTile(tile, tileName(i, i)));
		}
		console.log(tileCount);
		return (
			<div className="messageWrapper">
				<div className="statsBox">
					<table>
					<thead>
						<tr><th colSpan="3">Tiles played (double {game.setSize} set)</th></tr>
						<tr><th>Tile value</th><th>Count</th><th>Double played</th></tr>
					</thead>
					<tbody>
						{tileCount.map((cnt, i) =>
							<tr key={i}>
								<td>{i}</td>
								<td>{cnt}</td>
								<td>{doubles[i] ? 'yes' : 'no'}</td>
							</tr>
						)}
					</tbody>
					</table>
					<button onClick={() => this.props.onDone()}>Done</button>
				</div>
			</div>
		);
	}
}

/*
 * This player's hand.
 * Contain a header, the boneyard and a variable number of layout rowa to organize tiles.
 */
class Hand extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			layout: '',
			selected: -1,
			drawnTile: '',
		};
	}
	componentDidMount () {
		this.setState({
			rowWidth: 12,
		});
	}
	layout (...args) {
		if (args.length === 0) return (game.layout.split(';'));
		game.layout = args[0].join(';');
		this.setState({layout:game.layout});
	}
	render () {
		const self = this;
		const renderHandRow = function (row) {
			const renderTile = (tile, rowIndex) => {
				const layoutIndex = rowIndex + row * game.layoutCols;
				return (
					<Tile value={tile}
						key={rowIndex} orientation='V'
						selected={String(tile && isTile(tile, game.selected))}
						playable={tile && game.isMyTurn() && game.isPlayableTile(tile)}
						onClick={ev => self.handleClick(ev, tile, layoutIndex)}
						onDragStart={(ev) => self.handleDrag(ev, tile, layoutIndex)}
						onDragOver={(ev) => self.handleDragOver(ev, tile, layoutIndex)}
						onDrop={(ev) => self.handleDrop(ev, tile, layoutIndex)}
					/>
				);
			};
			const isRowEmpty = (row => {
				return (game.layout
					.slice(row * game.layoutCols, (row + 1) * game.layoutCols)
					.every(t => !t)
				);
			});
			return (
				<div className="handRow" key={row}>
					<div className="handRowTiles">
					{game.layout
						.slice(row * game.layoutCols, (row + 1) * game.layoutCols)
						.map(renderTile)
					}
					</div>
					<button onClick={ev => self.handleRowAdd(row, !isRowEmpty(row))}>
						{isRowEmpty(row) ? "-" : "+"}
					</button>
				</div>
			);
		};
		const renderHandRows = function () {
			let numRows = Math.ceil(game.layout.length / game.layoutCols);
			let rows = [];
			for (let r = 0; r < numRows; r++) {
				rows.push(renderHandRow(r));
			}
			return (rows);
		};
		const renderBoneyard = function () {
			return (
				<div className={"draw"+(game.boneyardSize ? "" : " boneyardEmpty")}>
					<div className="drawHeader">Boneyard</div>
					{game.drawnTile
						? <React.Fragment>
							<Tile value={game.drawnTile}
								playable={game.isPlayableTile(game.drawnTile)}
								selected={
									String(game.drawnTile
										&& game.selected === game.drawnTile
									)
								}
								onClick={ev => self.handleClick(ev, game.drawnTile, -1)}
								onDragStart={(ev) =>
									self.handleDrag(ev, game.drawnTile, -1)
							} />
							<div className="drawMsg" >
								{game.isMyTurn() && game.isPlayableTile(game.drawnTile)
									? "Move tile to train or hand"
									: "Move tile to hand"
								}
							</div>
						</React.Fragment>
						: (canDraw
							? <button className="canDraw"
									onClick={(ev)=>self.handleDraw(ev)}
								>
									Draw a tile
								</button>
							: <button>{game.boneyardSize} tiles</button>
						)
					}
				</div>
			);
		};
		let canDraw = (game.isMyTurn() && game.boneyardSize > 0
			&& !game.drawnTile && !game.hasPlayableTile());
		if (game.gameNum < 0) {
			return (<div className="hand"></div>);
		}
		return (
			<div className="hand">
				<div className="handHeader">
					<div>
						{game.test && game.local &&
							<button onClick={(ev) => game.showNextPlayer()}>
								Show Next player
							</button>
						}
						{game.test &&
							<button onClick={() => {debugger;}}>
								Debugger
							</button>
						}
						{game.test &&
							<button onClick={() => {game.setAutoPlay();}}>
								Autoplay
							</button>
						}
					</div>
					<div>Player: {game.playerName}</div>
					<div className="handPoints">{game.pointsInHand()} points in hand</div>
					<div className="turn">
						{game.isMyTurn() ? "Your turn" : ""}
					</div>
				</div>
				{renderBoneyard()}
				<div className="handRows">
					{renderHandRows()}
				</div>
			</div>
		);
	}
	handleClick (ev, tile, index) {
		ev.preventDefault();
		if (game.selected) {
			// move the selected tile to this position
			game.moveTile(game.selected, index);
			this.setState({
				layout: game.layout.join(';'),
				selected:game.selected,
				drawnTile:game.drawnTile,
			});
		} else if (tile) {
			// select the tile
			game.selected = tile;
			this.setState({selected:tile});
			console.log(`selected ${tile}`);
		}
	}
	handleDrag (ev, tile, index) {
		console.log(`handleDrag: ${tile}`);
		if (tile) {
			ev.dataTransfer.dropEffect = "move";
			ev.dataTransfer.setData("text/plain", tile);
		} else {
			ev.preventDefault();
		}
	}
	handleDragOver (ev) {
		ev.preventDefault();
	}
	handleDrop (ev, toTile, toIndex) {
		let tile = ev.dataTransfer.getData("text/plain");
		game.moveTile(tile, toIndex);
		this.setState({layout: game.layout.join(';'), selected:game.selected});
	}
	handleDraw (ev) {
		game.drawTile();
		this.setState({drawnTile: game.drawnTile});
		console.log(`handleDraw`);
	}
	handleRowAdd (row, add) {
		game.layoutRow(row, add);
		this.setState({layout: game.layout.join(';')});
	}
}

/*
 * A tile component.
 * Props:
 *  className: Class name for tile element
 *  orientation: 'H': horizontal, 'V': vertical
 *  value: tile value: a string of the form /\d+-\d+/. "" == blank tile
 *  selected: highlights tile as selected. Sets selected tile in game
 *  playable: highlights tile as playable
 *  onClick: click handler
 *  onDragStart: handler for a draggable tile
 *  onDragOver, onDrop: handlers for drop events
 *  spinner: show a spinner in the tile
 */
class Tile extends React.Component {
	render () {
		const renderTile = function (props, value) {
			let [v0, v1] = (value ? tileNums(value) : ['x', 'x']);
			return (
				<div  {...props} >
					{props.spinner
						? renderSpinner ()
						: null
					}
					{props.orientation === 'H'
						?	<table><tbody>
								<tr><td>{v0}</td><td>{v1}</td></tr>
							</tbody></table>

						:	<table><tbody>
								<tr><td>{v0}</td></tr>
								<tr><td>{v1}</td></tr>
							</tbody></table>
					}
				</div>
			);
		};
		let props = {
			className: (this.props.className ?  this.props.className+' ' : '')+'tile',
		};
		if (this.props.value && this.props.selected === 'true') {
			props.className += ' selected';
		};
		if (this.props.playable) {
			props.className += ' playable';
		};
		if (!this.props.value) props.className += ' blankTile';
		props.className += (this.props.orientation === 'H' ? ' horizTile' : ' vertTile');
		if (this.props.onClick) props.onClick = this.props.onClick;
		if (this.props.onDragStart) {
			props.draggable = 'true';
			props.onDragStart = this.props.onDragStart;
		}
		if (this.props.onDragOver) props.onDragOver = this.props.onDragOver;
		if (this.props.onDrop) props.onDrop = this.props.onDrop;
		if (this.props.playable) props.playable = 'true';
		props.orientation = this.props.orientation;
		props.spinner = this.props.spinner;
		return (renderTile(props, this.props.value));
	}
}

/*
 * Game or round winner message
 */
class Winner extends React.Component {
	render () {
		let lastWinner = game.players.reduce((p1, p2) =>
			(p1.lastRoundScore < p2.lastRoundScore ? p1 : p2)
		);		
		return (
			<div>
				<span className="roundWinnerText">
					{lastWinner.lastRoundScore === 0
						? (lastWinner.name === game.playerName
								? "You"
								: lastWinner.name
						   )+" won this round!"
						: "No one can play"
					}
				</span>
				<br /><br />
				<table class="scoreTable">
				<thead>
				<tr>
					<th>Player</th><th>Round</th><th>Total</th>
				</tr>
				</thead>
				<tbody>
					{game.players.map((p, i) =>
						<tr key={i}>
							<td>{p.name}</td>
							<td>{p.lastRoundScore}</td>
							<td>{p.score}</td>
						</tr>
					)}
				</tbody>
				</table>
				<br />
				{this.props.gameOver
					? <React.Fragment>
						<span className="gameWinnerText">
							{game.players.reduce((w, p) => {
									if (w.length === 0 || p.score < w[0].score) {
										return ([p]);
									}
									if (p.score === w[0].score) {
										w.push(p);
									}
									return (w);
								}, [])
								.map(p => p.name)
								.join(' and ')+' WINS THE GAME!'
							  }
						</span>
						<img className="justinImg" src={justinImg} alt="justin trudeau" />
					  </React.Fragment>
					: <React.Fragment>
						<br />Starting next round.
					  </React.Fragment>
				}
			</div>
		);
	}
}

/*
 * render a spinner.
 */
function renderSpinner () {
	return (
		<div className="spinner">
			{Array(12).fill(null).map((v, i) => <div key={i}></div>)}
		</div>
	);
}

export default App;
