/*eslint semi: ["error", "always"]*/
import {
	tileName, tileNums, tileFlip, isTile, tileHasValue, isDoubleTile
} from './util';

const initialDraw = [0, 0, 8, 8, 12, 12, 12, 10, 10, 11, 11, 11, 11, 11, 11];
const setSize =     [0, 0, 9, 9, 12, 12, 12, 12, 12, 15, 15, 15, 15, 18, 18];

/*
 * Local version of the server.
 */
export class LocalServer {
	createGame (playerNames, gameCount) {
		this.setSize = setSize[playerNames.length];
		this.gameNum = gameCount || this.setSize;
		this.moveNum = 0;
		this.turnMove = 0;
		this.curPlayerIndex = -1;
		this.uncoveredDouble = -1;
		this.players = playerNames.map((name, i) => {
			return ({
				name: name,
				joined: (i === 0),	// first player has joined the game by creating
				score: 0,
				lastRoundScore: -1,
				hand: [],
				train:[],
				public: false,
			});
		});
		this.initialDraw = initialDraw[playerNames.length];
		this.mexTrain = [];
		this.gameId = String(Math.floor(Math.random() * 10000));
		this._initRound(this.gameNum);
		console.log(`ctl.creatGame: gameId=${this.gameId}`);
		return (this.gameId);
	}
	joinGame (gameId, playerName) {
		console.log(`ctl.joinGame(${gameId}, ${playerName})`);
		let player = this._findPlayer(playerName);
		if (gameId !== this.gameId || !player) {
			console.log(`ctl.joinGame: Can't find game or player`);
			return (false);
		}
		if (player.joined) {
			console.log(`ctl.joinGame: Player already joined`);
			return (false);
		}
		player.joined = true;
		return (true);
	}
	getGameState (gameId, playerName) {
		console.log(`ctl.getGameState(${gameId}, ${playerName})`);
		if (gameId !== this.gameId || !this._findPlayer(playerName)) {
			console.log(`ctl.getGameState: Can't find game or player`);
			return (null);
		}
		return (this._getPlayerState(playerName));
	}
	playTile (gameId, playerName, gameNum, moveNum, tile, trainName, drawnTile) {
		console.log(`ctl.playTile(${gameId}, ${playerName}, ${gameNum}, ${moveNum}, ${tile}, ${trainName})`);
		let player = this._findPlayer(playerName);
		
		console.assert(
			gameId === this.gameId && gameNum === this.gameNum
				&& moveNum === this.moveNum && player
				&& playerName === this.players[this.curPlayerIndex].name,
			`ctl.playTile: bad parameter match`
		);
		if (drawnTile) {
			console.assert(drawnTile === this.boneyard[0],
				`ctl.playTile: bad drawn tile`);
			this.boneyard.shift();
			player.hand.push(drawnTile);
		}
		this.moveNum++;
		if (tile) {
			this._removeFromHand(player.hand, tile);
			this._addTileToTrain(player, tile, trainName);
			// if no uncovered double go to the next player
			if (this.uncoveredDouble < 0) {
				this.turnMove = 0;
				this._nextPlayer();
			} else {
				this.turnMove++;
			}
		} else {
			// can't play a tile
			player.public = true;
			this.turnMove = 0;
			this._nextPlayer();
		}
		return (this._getPlayerState(playerName));
	}
	_initRound (gameNum) {
		this.gameNum = gameNum;
		this.moveNum = 0;
		this.turnMove = 0;
		this.curPlayerIndex = -1;
		this.hasInitialTile = false;
		this.uncoveredDouble = -1;
		this.mexTrain.length = 0;
		for (let p of this.players) {
			p.hand.length = 0;
			p.train.length = 0;
			p.public = false;
		}
		if (gameNum >= 0) {
			// get a random bonyard
			this.boneyard = this._randomBoneyard();
			// find the position of the initial double
			let p1 = this.boneyard.indexOf(tileName(this.gameNum));
			let nPlayers = this.players.length;

			if (p1 >= nPlayers * this.initialDraw) {
				// limit init double position to full nPlayer draws.
				let lim = Math.min(
					p1,
					(Math.floor(this.boneyard.length / nPlayers) * nPlayers)
				);
				// pick a random position lower than limit
				let p2 = Math.floor(Math.random() * lim);
				this.boneyard[p1] = this.boneyard[p2];
				this.boneyard[p2] = tileName(this.gameNum);
				console.log(`_initRound: moving initial tile from ${p1} to ${p2}`);
			}
	
			// draw hand until you've got the required number and init double
			for (let i = 0; i < this.initialDraw || this.curPlayerIndex < 0; i++) {
				for (let [index, p] of this.players.entries()) {
					p.hand.unshift(this._drawFromBoneyard());
					if (p.hand[0] === tileName(this.gameNum, this.gameNum)) {
						this.curPlayerIndex = index;
						console.log(`ctl._initRound: ${p.name} starts`);
					}
				}
			}
		} else {
			this.boneyard = [];
			this.players.length = 0;
			this.curPlayerIndex = -1;
			this.gameNum = -1;
		}
	}
	_getPlayerState (playerName) {
		const gs = {
			// shared with all players
			playerName: playerName,
			setName: 'double-'+this.setSize,
			initialDraw: this.initialDraw,
			gameNum: this.gameNum,
			moveNum: this.moveNum,
			turnMove: this.turnMove,
			hasInitialTile: this.hasInitialTile,
			uncoveredDouble: this.uncoveredDouble,
			curPlayerIndex: this.curPlayerIndex,
			boneyardSize: this.boneyard.length,
			boneyardTop: this.boneyard.slice(0,2),
			players: this.players.map(p => ({
				name: p.name,
				joined: p.joined,
				score: p.score,
				lastRoundScore: p.lastRoundScore,
				tiles: p.hand.length,
				train: p.train.slice(),
				public: p.public,
			})),
			mexTrain: this.mexTrain.slice(),
			// local to requesting player
			hand: (this.gameNum >= 0 ? this._findPlayer(playerName).hand.slice() : []),
		};
		console.log(`ctl._getPlayerState: ${JSON.stringify(this)}`);
		return (gs);
	}
	_nextPlayer () {
		if (this.players[this.curPlayerIndex].hand.length === 0
			&& this.uncoveredDouble < 0
		) {
			this._winner();
		}
		if (this.boneyard.length > 0) {
			this.curPlayerIndex = ++this.curPlayerIndex % this.players.length;
		} else {
			for (let i = 1; i <= this.players.length; i++) {
				let index = (this.curPlayerIndex + i) % this.players.length;
				let player = this.players[index];
				if (player.hand.some(tile => this._isPlayableTile(player, tile))) {
					this.curPlayerIndex = index;
					return;
				}
			}
			// no one can play
			this._winner();
		}
	}
	_winner () {
		console.log(`ctl._winner: ${this.players[this.curPlayerIndex].name}`);
		// add up the score for each player
		for (let p of this.players) {
			let score = 0;
			p.hand.forEach((tile) => {
				if (tile === tileName(0, 0)) {
					score += 50;
				} else {
					score += tileNums(tile).reduce((t, v) => Number(t) + Number(v));
				}
			});
			p.lastRoundScore = score;
			p.score += score;
		}
		// initialize the next round
		this._initRound(this.gameNum - 1);
	}
	_findPlayer (playerName) {
		let player = this.players.find(p => p.name === playerName);
		console.assert(player, `findPlayer: can't find player $(playerName)`);
		return (player);
	}
	_findTrain (trainName) {
		return (
			(trainName ? this._findPlayer(trainName).train : this.mexTrain)
		);
	}
	_trainValue (trainName) {
		const train = this._findTrain(trainName);
		return (String(train.length === 0 ? this.gameNum : tileNums(train[0])[1]));
	}
	_addTileToTrain (player, tile, trainName) {
		if (!this.hasInitialTile) {
			 console.assert(isTile(tile, tileName(this.gameNum)) && !trainName,
				"ctl._addTileToTrain: Bad initial tile");
			this.hasInitialTile = true;
			this.uncoveredDouble = this.gameNum;
		} else {
			let trainValue = this._trainValue(trainName);
			tile = (tileNums(tile)[0] === trainValue ? tile : tileFlip(tile));
			console.assert(tileNums(tile)[0] === trainValue,
				"ctl._addTileToTrain: bad tile");
			this._findTrain(trainName).unshift(tile);
			if (trainName === player.name) {
				player.public = false;
			}
			if (isDoubleTile(tile)) {
				this.uncoveredDouble = Number(tileNums(tile)[0]);
			} else {
				this.uncoveredDouble = -1;
			}
		}
	}
	_isPlayableTile (player, tile) {
		const canPlayOnTrain = ((tile, train) =>
			tileHasValue(tile,
				(train.length === 0 ? this.gameNum : tileNums(train[0])[1])
			)
		);
		if (!this.hasInitialTile) {
			return (isTile(tile, tileName(this.gameNum)));
		}
		if (this.uncoveredDouble >= 0) {
			return (tileHasValue(tile, this.uncoveredDouble));
		}
		if (canPlayOnTrain(tile, this.mexTrain)) {
			return (true);
		}
		return (this.players.some((p) => {
			return (
				(p.name === player.name || p.public)
					&& canPlayOnTrain(tile, p.train)
			);
		}));
	}
	_removeFromHand (hand, tile) {
		let index = hand.findIndex(t => isTile(tile, t));
		console.assert(index >= 0, `removeFromHand: can't find tile ${tile}`);
		hand.splice(index, 1);
	}
	_drawFromBoneyard () {
		return (this.boneyard.shift());
	}
	_randomBoneyard (size) {
		let boneyard = [];
		let swap = (a, b) => {
			let tmp = boneyard[a];
			boneyard[a] = boneyard[b];
			boneyard[b] = tmp;
		};

		for (let i = 0; i <= this.setSize; i++) {
			for (let j = i; j <= this.setSize; j++) {
				boneyard.push(tileName(i, j));
			}
		}
		// shuffle
		for (let i = 0; i < boneyard.length * 5; i++) {
			swap(
				Math.floor(Math.random() * boneyard.length),
				Math.floor(Math.random() * boneyard.length)
			);
		}
		boneyard.forEach((t, i) => {
			if (Math.random() > 0.5) {
				boneyard[i] = tileFlip(t);
			}
		});
		// make sure the double for gameNum is first XXX
		// swap(0, boneyard.indexOf(tileName(this.gameNum)));
		console.log(boneyard);
		return (boneyard);
	}
};
