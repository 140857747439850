/*eslint semi: ["error", "always"]*/
import React from 'react';

/*
 * Help button and help
 */
export default class Help extends React.Component {
	render() {return (
		<div className="help">
			<h2>Creating a game</h2>
			<p>
			When you first start the game, you'll see a dialog that asks you to
			join an existing game or create a new game.
			If you press the "Create a new game" button, you'll see a dialog that asks
			asks you to enter the names of two to six players.
			The player names can be any letters, numbers, or spaces.
			Any leading or trailing spaces are ignored.
			The first player name will be your name in the game.
			</p><p>
			Press the "Start game" button when you're done.
			You'll then see a message telling you the game ID.
			The game ID is a four-digit number that you can communicate to the
			other players, along with their player names.
			The player trains in the arena (see below) will indicate whether
			each player has joined or not.	  			
			</p>
			<h2>Joining a game</h2>
			<p>
			When you first start the game, you'll see a dialog that asks you to
			join an existing game or create a new game.
			If you enter the player name and the game ID given to you by the person
			who create the game, you can then hit the "Join game" button.
			</p>
			<h2>Rejoining a game</h2>
			<p>
			If you accidentally kill the browser window for the game, you can
			rejoin the game using your player name and the game ID.
			Your tile organization is not preserved, so you'll have to reorganize
			your tiles when you do this.
			</p>
			<h2>Arena</h2>
			<p>
			Once the game is started, the upper part of the game board is the arena
			that contains the initial tile, the Canadian (a.k.a Mexican) train, and
			the trains for each player.
			Each player's train contains a box with information on the player's score
			and the number of tiles remaining in the player's hand.
			If a player has only one tile remaining, this will be highlighted in
			red.
			</p><p>
			The information box for the player whose turn it is turns white with a
			red border.
			If a player has a public train, then a train icon appears
			in the information box above the train.
			When it's your turn, the trains that are playable (the Canadian train,
			your train, or public trains) are highlighted in white.
			</p>
			<h2>Hand</h2>
			<p>
			The lower part of the game board contains your hand and the boneyard.
			Your hand is in one or more layout rows.
			You can add an empty row by hitting the "+" button to the right of a row:
			A new empty row will appear below that row.
			Empty rows can be removed by hitting the "-" button
			to the right of the row.
			</p><p>
			You can organize the tile in the layout rows any way you want, by either
			dragging and dropping them or by clicking on a tile to select it,
			which highlights it in red, and then clicking on the new position.
			Double-clicking a tile will flip it.
			If you drop a tile on an existing tile, the contiguous group of tiles
			at that position and to the right will be shifted one position right
			to accommodate the dropped tile.
			This will add another layout row at the end, if required.
			Dragging a tile on top of itself will flip it.
			</p>
			<h2>Playing</h2>
			<p>
			When it's your turn the playable tiles in your hand are highlighted in
			green.
			All the available trains will be highlighted in white, though the train
			may not be suitable for the tiles in your hand.
			You can play a tile on a train or the initial tile by
			dragging and dropping it.
			Alternatively, you can select a tile (click it to highlight it in red) and
			then click place you want to play.
			Lastly, if there's only one playable tile, you can simply click the
			the place you want to play.
			</p>
			<h2>Boneyard</h2>
			<p>
			If you have no playable tiles you'll have to draw from the boneyard.
			In this case, the boneyard will show the "Draw a tile" button
			and be highlighted in green.
			If you have a playable tile, or it's not your turn, the boneyard will
			indicate the number of tiles left in the boneyard.
			</p><p>
			When there are no tiles left in the boneyard, your turn will be skipped,
			and the play passes to the next player with playable tiles.
			If no player has playable tiles, the round it ended and the value of
			each player's hand is added to their score.
			</p>
			<h2>Train stats</h2>
			<p>
			The player trains in the arena only shows the last tile in each train.
			You can see a summary of played tiles in all the trains by pressing the
			"Train stats" button.
			The table shows the number of tiles with each possible value that have been
			played.
			There are 10 tiles with each value in a double-9 set.
			There are 13 tiles with each value in a double-12 set.
			It also indicates whether the double of that value has been played or not.
			</p>
			<div><button onClick={() => this.props.onDone()}>Done</button></div>
		</div>
	);}
}
