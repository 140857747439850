/*eslint semi: ["error", "always"]*/
import {
	LocalServer
} from './localServer';
import {
	assert,
	sound,
	tileName, tileNums, tileFlip, isTile, tileHasValue, isDoubleTile
} from './util';

/*
 * Constants
 */
export const test = false;			// test mode
export const local = false;			// local mode
export const MAX_PLAYERS = 6;
export const MIN_PLAYERS = 2;
// this must match $setSizes in createGame.php
export const START_NUM = [0, 0, 9, 9, 12, 12, 12, 12, 12, 15, 15, 15, 15, 18, 18];

const MOVE_POLL_INTERVAL = 2000;		// msec to poll for other player moves
const GAME_TIMEOUT =  60 * 60 * 1000;	// msec of inactivity to end game
const svr = new LocalServer();
const SERVER = (window.location.hostname.match(/localhost/)	// game api
	? 'https://train.srkleiman.com/'
	: window.location.href.replace(/\/[^/]*$/, '/')	// strip the last name in the URL
);

const LAYOUT_COLS = 10;		// number of tiles in each layout row
const LAYOUT_ROWS = 2;		// initial number of row in hand area

/*
 * The local state of the current game for the current player.
 * Calls the server to update game state for all players.
 * Note: each client polls the server when not the current player to update all player
 * and train information. When it's this player's turn, the server provides all the
 * the information for this player to complete the turn without waiting for a response
 * from the server. The server provides the two top boneyard tiles in case the player
 * needs to draw and draw again to cover a double.
 */
export class Game {
	constructor (onChangeFn, onWinnerFn) {
		this.test = test;
		this.local = local;
		this.onChangeFn = onChangeFn;
		this.onWinnerFn = onWinnerFn;
		this.gameId = '';
		this.stateNum = 0;
		this.playerName = '';
		this.showDrop = new Map();
		this.layoutCols = LAYOUT_COLS;
		this._playerCache = null;			// cache for test mode
		// this is player local state
		this.layout = [];
		this.drawnTile = '';
		this.lastDrawnTile = '';
		this.selected = '';
		this.message = '';
		this.messageQ = [];
		this.waiting = false;				// operation pending at the server
		// this state is overwritten by _setGameState
		this.playerIndex = -1;
		this.curPlayerIndex = -1;
		this.gameNum = -1;
		this.moveNum = -1;
		this.turnMove = -1;
		this.players = [];
		this.mexTrain = [];
		this.hand = [];
		this.pollTimer = 0;
		if (!local) {
			this._checkInactive();
		}
	}

	/*
	 * Server API
	 */
	// create a game
	createGame (playerNames, rounds, cb) {
		let request;
		console.log(`game.createGame(${JSON.stringify(playerNames)})`);
		assert(cb,`game.createGame: no callback`);
		if (
			this.isGame() || this.waiting
			|| playerNames.length < MIN_PLAYERS || playerNames.length > MAX_PLAYERS
			|| playerNames.some(n => !n.match(/[\w\d ]+/))
		) {
			return (false);
		}
		playerNames.forEach((n, i) => {playerNames[i] = n.trim();});
		// setup player cache in local mode
		if (test && local) {
			this._playerCache = new Map(playerNames.map((name) =>
				[	name,
					{
						playerName: '',
						playerIndex: -1,
						curPlayerIndex: -1,
						gameId: '',
						gameNum: -1,
						moveNum: -1,
						turnMove: -1,
						players: [],
						mexTrain: [],
						hand: [],
						layout: [],
						drawnTile: '',
						lastDrawnTile: '',
						selected: '',
						message: '',
						messageCb: null,
					},
				]
			));
		}
		// create the game on the server
		this.waiting = true;
		if (local) {
			// local mode, create a Promise for the embedded controller
			request = new Promise((resolve, reject) => {
				let gameId = svr.createGame(playerNames);
				if (gameId) {
					resolve(gameId);
				} else {
					reject(new Error());
				}
			});
		} else {
			let url = new URL(`${SERVER}/createGame.php`);
			url.searchParams.append('opVersion', '1');
			url.searchParams.append('playerNames', JSON.stringify(playerNames));
			url.searchParams.append('rounds', JSON.stringify(rounds));
			request = fetch(url)
				.then(response => {
					if (response.ok) {
						return (response.json());
					} else {
						throw (new Error(response.status));
					}
				});
		}
		// process the response
		request
			.then(gameId => {
				// success: setup game
				this.waiting = false;
				gameId = String(gameId);
				this.gameId = gameId;
				this.playerName = playerNames[0];
				console.log(`game.createGame: created gameId ${gameId}`);
				// ask to join and get game state
				this.getMove('join');
				cb(true);
			})
			.catch((err) => {
				// error. setup the error message
				this.waiting = false;
				let msg = (err && err.message ? `(${err.message})` : '');
				this.waiting = false;
				this.onChangeFn();
				cb(false, msg);
			});
		return (true);
	}
	// join a game
	joinGame (gameId, playerName, cb) {
		let request;
		console.log(`game.joinGame(${gameId}, ${playerName})`);
		gameId = gameId.trim();
		if (this.isGame() || this.waiting
			|| !gameId.match(/\d+/)
			|| !playerName.match(/[\w\d][\w\d ]*/)
		) {
			return (false);
		}
		playerName = playerName.trim();
		this.waiting = true;
		if (local) {
			request = new Promise((resolve, reject) => {
				if (svr.joinGame(gameId, playerName)) {
					console.log(`game.joinGame: succeeded`);
					resolve();
				} else {
					console.log(`game.joinGame: failed`);
					reject(new Error());
				}
			});
		} else {
			let url = new URL(`${SERVER}/joinGame.php`);
			url.searchParams.append('opVersion', '1');
			url.searchParams.append('gameId', gameId);
			url.searchParams.append('playerName', playerName);
			request = fetch(url)
				.then(response => {
					if (!response.ok) {
						console.log(`game.joinGame failed: ${response.status}`);
						if (response.status === 404) {
							throw (new Error(`Can't find game`));
						}
						if (response.status === 403) {
							throw (new Error(`Can't find player`));
						}
						throw (new Error(response.status));
					}
				});
		}
		request
			.then(() => {
				this.waiting = false;
				this.gameId = gameId;
				this.playerName = playerName;
				this.getMove('join');
				cb(true);
			})
			.catch((err) => {
				this.waiting = false;
				
				let msg = (err && err.message ? `(${err.message})` : '');
				this.onChangeFn();
				cb(false, msg);
			});
		return (true);
	}
	// get the current move. If onlyOne is true, then don't queue another request
	// and just return. reason is passed to _setGameState.
	getMove (reason='get', onlyOne) {
		console.log(`game.getMove(${reason}) ${this.gameId}, ${this.playerName}`);
		if (reason === 'join' ? this.isGame() : !this.isGame()) {
			return (false);
		}
		if ((onlyOne && this.getRequest) || (reason === 'get' && this.playRequest)) {
			return (false);
		}

		this.getRequest = this.getRequest || Promise.resolve(null);
		if (local) {
			this.getRequest = this.getRequest
			.then(() => {
				let gameState = svr.getGameState(this.gameId, this.playerName);
				if (gameState) {
					return (gameState);
				} else {
					return (Promise.reject(new Error()));
				}
			});
		} else {
			this.getRequest = this.getRequest
			.then(() => {
				let url = new URL(`${SERVER}/getGameState.php`);
				url.searchParams.append('opVersion', '1');
				url.searchParams.append('gameId', this.gameId);
				url.searchParams.append('playerName', this.playerName);
				return (fetch(url));
			})
			.then(response => {
				if (response.ok) {
					return (response.json());
				} else {
					console.log(`game.getMove failed: ${response.status}`);
					throw (new Error(response.status));
				}
			});
		}
		this.getRequest = this.getRequest
		.then(gameState => {
				if (gameState.gameNum === this.gameNum) {
					// same game. Internal and server hands should match
					assert(
						gameState.hand.length === this.hand.length
							&& gameState.hand.every(t => this.hasTileInHand(t))
							&& gameState.players[this.playerIndex].tiles === this.hand.length,
						`getMove: hands don't match`
					);
				}
			this._setGameState(gameState, reason);	// setup the new state
			this.getRequest = null;					// note no request pending
			// update UI
			this.stateNum++;
			this.onChangeFn();
		})
		.catch((err) => {
			let msg = (err && err.message ? `(${err.message})` : '');
			this.notice(`Server can't get game state from server ${msg}`);
			this.getRequest = null;					// note no request pending
			// update UI
			this.stateNum++;
			this.onChangeFn();
		});
		return (true);
	}
	// play a tile on a train. A trainName of '' indicates the Mexican train.
	// No tile ('') and no train ('') indicates end of turn
	playTile (tile, trainName) {
		// if no tile, or playing the last drawn tile, note tile drawn
		let drawnTileUsed = (!tile || isTile(tile, this.lastDrawnTile)
			? this.lastDrawnTile
			: ''
		);
		console.log(`game.playTile(${this.gameId}, ${this.playerName}, `
			+`${this.gameNum}, ${this.moveNum}, `
			+`${tile}, ${trainName}, ${drawnTileUsed})`
		);

		if (tile) {
			// do initial checks
			if (!this.canPlayTileOnTrain(tile, trainName)) {
				return (false);
			}
			this.removeTileFromHand(tile);
			if (this.hasInitialTile) {
				if (tileNums(tile)[0] !== tileNums(this.trainTop(trainName))[1]) {
					tile = tileFlip(tile);
				}
				this.findTrain(trainName).unshift(tile);
			}
			this.hasInitialTile = true;
			if (isDoubleTile(tile)) {
				this.uncoveredDouble = Number(tileNums(tile)[0]);
				if (this.boneyardSize === 0) {
					this.turnMove = -1;
				} else {
					this.turnMove++;
				}
			} else {
				this.turnMove = -1;
			}
		} else {
			// a null tile means the turn is done after a tile is drawn
			// the server is called just to move to the next player
			this.turnMove = -1;
		}
		if (this.turnMove === -1) {
			console.log(`turn done`);
			this.lastDrawnTile = '';
		}
		// update the UI
		this.stateNum++;
		this.onChangeFn();

		// send it to the server
		let lastMoveInTurn = (this.turnMove < 0);
		this.playRequest = this.playRequest || Promise.resolve(null);
		if (local) {
			// use GameCtl
			this.playRequest = this.playRequest
			.then(() => {
				let gameState = svr.playTile(
					this.gameId, this.playerName, this.gameNum, this.moveNum,
					tile, trainName, drawnTileUsed
				);
				if (gameState) {
					return (gameState);
				} else {
					return (Promise.reject(new Error('')));
				}
			});
		} else {
			// use the remote server
			this.playRequest = this.playRequest
			.then(() => {
				let url = new URL(`${SERVER}/playTile.php`);
				url.searchParams.append('opVersion', '1');
				url.searchParams.append('gameId', this.gameId);
				url.searchParams.append('playerName', this.playerName);
				url.searchParams.append('gameNum', this.gameNum);
				url.searchParams.append('moveNum', this.moveNum);
				url.searchParams.append('tile', tile);
				url.searchParams.append('trainName', trainName);
				url.searchParams.append('drawnTile', drawnTileUsed);
				return (fetch(url));
			})
			.then(response => {
				if (response.ok) {
					return (response.json());
				} else {
					return (Promise.reject(new Error(response.status)));
				}
			});
		}
		this.playRequest = this.playRequest
		.then(gameState => {
			if (lastMoveInTurn) {
				// last move in turn
				if (gameState.gameNum === this.gameNum) {
					// same game. Internal and server hands should match
					assert(
						gameState.hand.length === this.hand.length
							&& gameState.hand.every(t => this.hasTileInHand(t))
							&& gameState.players[this.playerIndex].tiles === this.hand.length,
						`playTile: hands don't match`
					);
					assert(gameState.players.every((p, pi) =>
						p.train.length === this.players[pi].train.length
							&& p.train.every((t, ti) => t === this.players[pi].train[ti])
						),
						`playTile: trains don't match'`
					);
				}
				// note that all play requests have completed
				this.playRequest = null;
				// set game state (perhaps a new game) and update UI accordingly
				this._setGameState(gameState, 'playDone');
			} else {
				// turn continues after playing double
				assert(this.isGame(), `playTile: Playing on non-existant game`);
				assert(gameState.players[this.playerIndex].name === this.playerName,
					`playTile: player names don't match`);
				assert(gameState.curPlayerIndex === this.playerIndex
						&& gameState.gameNum === this.gameNum,
					`playTile: incorrect current player or game`
				);
				// set game state (perhaps a new game) and update UI accordingly
				this._setGameState(gameState, 'playCont');
			}
			this.stateNum++;
			this.onChangeFn();
		})
		.catch((err) => {
			this.waiting = false;
			let msg = (err && err.message ? `(${err.message})` : '');
			this.notice(
				`Server can't play ${tile} on ${trainName || "Canadian train"} ${msg}`
			);
			this.stateNum++;
			this.onChangeFn();
			if (lastMoveInTurn) {
				this.playRequest = null;
			}
		});
		return (true);
	}

	// set the game state from the state returned by the server.
	// reason:
	//   'join': joining or re-joining a game. reset everything to server state
	//   'playDone': played a tile or drew and can't play further. Check consistency,
	//           update game state,check for winner
	//   'playCont': played double and need to continue. Check consistency.
	//   'get': polling for game state. Update game state, check for my turn,
	//          check for winner
	//   'getJoined': just update joining status (at start of game)
	_setGameState (gameState, reason='get') {
		let newLayout = (() => {
			let layoutRows = Math.max(
				Math.floor(gameState.hand.length / LAYOUT_COLS) + 1,
				LAYOUT_ROWS
			);
			if (gameState.gameNum >= 0) {
				this.hand = gameState.hand;
				this.layout = [
					...this.hand,
					...Array((layoutRows * LAYOUT_COLS) - this.hand.length).fill('')
				];
				if (gameState.hand.length > gameState.initialDraw) {
					this.notice(
						`Drew ${gameState.hand.length - gameState.initialDraw} extra tiles`
						+` to find initial double`
					);
				}
			} else {
				this.hand = [];
				this.layout = Array(LAYOUT_ROWS * LAYOUT_COLS).fill('');
			}
			this.drawnTile = '';
			this.selected = '';
		});
		console.log(`game._setGameState (${JSON.stringify(gameState)}, ${reason})`);
		if (!gameState) {
			console.log("setGameState: no game state");
			return;
		}
		if (this.playerName !== gameState.playerName) {
			console.log(`game._setGameState: wrong player name`);
			assert(test, `game._setGameState: wrong player name`);
			return;
		}
		switch (reason) {
		case 'join':		// get game state after joining
			assert(!this.isGame(), `_setGameState: Joining game in progress`);
			this.gameNum = gameState.gameNum;
			this.setSize = Number(gameState.setName.replace(/^double-/, ''));
			this.players = gameState.players;
			this.playerIndex = this.players.findIndex(p => p.name === this.playerName);
			newLayout();
			if (test && local && this._playerCache == null) {
				this._playerCache = new Map(this.players.map(p => {
					let name = p.name;
					return ([
						name,
						{
							playerName: '',
							playerIndex: -1,
							curPlayerIndex: -1,
							gameId: '',
							gameNum: -1,
							moveNum: -1,
							turnMove: -1,
							players: [],
							mexTrain: [],
							hand: [],
							layout: [],
							drawnTile: '',
							lastDrawnTile: '',
							selected: -1,
							message: '',
							messageQ: [],
						},
					]);
				}));
			}
			break;
		case 'getJoined':		// only used when someone hasn't joined the game yet
			// just update joined status
			assert(this.isGame(), `_setGameState: Polling for non-existant game`);
			gameState.players.forEach((p, i) => this.players[i].joined = p.joined);
			return;
		case 'playCont':		// game state after playing a tile, but turn continues
			this.moveNum = gameState.moveNum;	// just update moveNum
			return;
		case 'playDone':		// gaem state at end of turn
			// move curPlayerIndex to make sure it's different if the server returns
			// play to us. This can happen if we're the only playable hand.
			this.curPlayerIndex = (this.curPlayerIndex + 1) % this.players.length;
			break;
		default:
		case 'get':
			assert(this.isGame(), `_setGameState: Polling for non-existant game`);
			assert(gameState.players[this.playerIndex].name === this.playerName,
				`_setGameState: player names don't match`);
			// don't re-update for moves we haven't seen
			if (gameState.gameNum === this.gameNum && gameState.moveNum <= this.moveNum) {
				// joining doesn't update moveNum, so we check and update as required
				if (this.players.some(p => !p.joined)) {
					this.players.forEach((p, i) => {
						p.joined = p.joined || gameState.players[i].joined;
					});
					this.stateNum++;
					this.onChangeFn();
				}
				// discard old state
				console.log(`game._setGameState: discarding old move`);
				return;
			}
			// check whether a player's train has changed and show the drop.
			// ignore this player since that's already been shown.
			this.players.forEach((player, index) => {
				if (player.name !== this.playerName
					&& player.train[0] !== gameState.players[index].train[0]
				) {
					if (this.showDrop && this.showDrop.has(player.name)) {
						this.showDrop.get(player.name)();
					}
				}
			});
			// check for first drop on the mexican train.
			if (this.mexTrain.length === 0 && gameState.mexTrain.length) {
				sound.play('newTrain');
			}
			// same for the Mexican train
			if (this.mexTrain[0] !== gameState.mexTrain[0]) {
				if (this.showDrop && this.showDrop.has("")) {
					this.showDrop.get("")();
				}
			}
			// play the chapping sound if another player has only one tile
			// where they had more before
			if (this.players.some((p, i) =>
				p.name !== this.playerName && p.tiles > 1
					&& gameState.players[i].tiles === 1
			)) {
				sound.play('chap');
			}
			// play train sound if another player got a train
			if (this.players.some((p, i) =>
				!p.public && gameState.players[i].public
			)) {
				sound.play('train');
			}
			// the gameState hand should match our hand
			// note: our hand may have the drawn tile, but the server won't until
			// we call playTile, which will increase the moveNum.
			assert(gameState.gameNum !== this.gameNum
				|| (gameState.hand.length === this.hand.length
					&& gameState.hand.every(t => this.hasTileInHand(t))
					&& gameState.players[this.playerIndex].tiles === this.hand.length),
				`_setGameState: hands don't match`
			);
			break;
		}

		this._checkLayout('_setGameState');
		if (reason === 'get' && gameState.moveNum > this.moveNum) {
			sound.play('drop');
		}
		this.players = gameState.players;
		if (gameState.gameNum >= 0) {
			this.moveNum = gameState.moveNum;
			this.boneyardSize = Number(gameState.boneyardSize);
			this.boneyardTop = gameState.boneyardTop;
			this.hasInitialTile = gameState.hasInitialTile;
			this.uncoveredDouble = Number(gameState.uncoveredDouble);
			this.mexTrain = gameState.mexTrain;

			if (this.curPlayerIndex !== gameState.curPlayerIndex) {
				console.log(`game._setGameState:`
					+` ${this.players[gameState.curPlayerIndex].name}'s turn`);
				if (gameState.curPlayerIndex === this.playerIndex) {
					this.turnMove = 0;
					if (this.uncoveredDouble >= 0) {
						sound.play('uncoveredDouble');
					}
					sound.play('yourTurn');
				} else {
					this.turnMove = -1;
				}
			}
			this.curPlayerIndex = gameState.curPlayerIndex;

			assert(this.gameNum < 0 || this.playerIndex >= 0,
				"setGameState: can't find player");
		} else {
			// end of last game
			this.moveNum = 0;
			this.boneyardSize = 0;
			this.boneyardTop = [];
			this.hasInitialTile = false;
			this.uncoveredDouble = -1;
			this.mexTrain = [];
			this.hand = [];
		}
		if (gameState.gameNum < this.gameNum) {
			this.gameNum = gameState.gameNum;
			// someone won
			this.onWinnerFn();
			newLayout();
			if (this.gameNum >= 0) {
				sound.play('roundWinner');
				console.log(`game._setGameState: new round`);
			} else {
				sound.play('gameWinner');
			}
		} else {
			this.gameNum = gameState.gameNum;
		}
	}

	/*
	 * Local game functions
	 */
	// draw a tile
	drawTile () {
		console.log(`game.drawTile(${this.gameId}, ${this.playerName},`
			+` ${this.gameNum}, ${this.moveNum})`);
		if (!this.isMyTurn() || this.drawnTile || this.boneyardTop.length === 0) {
			return (false);
		}
		assert(!this.hasPlayableTile(), `drawTile: has playable tile`);
		this.drawnTile = this.lastDrawnTile = this.boneyardTop.shift();
		this.boneyardSize--;
		this.addTileToHand(this.drawnTile);
		if (!this.isPlayableTile(this.drawnTile)) {
			this.playTile('', '');			// tell server end of turn
		}
		// update the UI
		this.stateNum++;
		this.onChangeFn();
		return (true);
	}
	// return true if a game is in progress
	isGame () {
		return (!!this.gameId && this.playerName && this.gameNum >= 0);
	}
	// return true if it's my turn
	isMyTurn () {
		return (this.isGame() && this.playerIndex === this.curPlayerIndex
			&& this.turnMove >= 0);
	}
	// return true if tile is playable on some train
	isPlayableTile (tile) {
		if (this.gameNum < 0 || !tile) {
			return (false);
		}
		if (!this.hasInitialTile) {
			return (isTile(tile, tileName(this.gameNum)));
		}
		if (this.uncoveredDouble >= 0) {
			return (tileHasValue(tile, this.uncoveredDouble));
		}
		if (this.canPlayTileOnTrain(tile, "")) {
			return (true);
		}
		return (this.players.some(p => this.canPlayTileOnTrain(tile, p.name)));
	}
	// return true if any tile in my hand tile is playable on some train
	hasPlayableTile () {
		if (this.gameNum < 0) {
			return (false);
		}
		return (this.hand.some(tile => this.isPlayableTile(tile)));
	}
	// return true if tile is contained in my hand
	hasTileInHand (tile) {
		return (this.hand.some(t => isTile(t, tile)));
	}
	// return true if trainName is playable (public or uncovered double)
	isPlayableTrain (trainName) {
		if (!this.isMyTurn() || !this.hasInitialTile) {
			return (false);
		}
		const train = this.findTrain(trainName);
		const trainTop = String(train.length === 0
			? tileName(this.gameNum)
			: train[0]
		);
		if (this.uncoveredDouble >= 0 && this.uncoveredDouble !== this.gameNum) {
			return (isTile(trainTop, tileName(this.uncoveredDouble)));
		}
		return (!trainName
			|| trainName === this.playerName || this.findPlayer(trainName).public);
	}
	// return true if tile can be played on trainName
	canPlayTileOnTrain (tile, trainName) {
		if (!tile || !this.isMyTurn()) {
			return (false);
		}
		if (!this.hasInitialTile) {
			return (!trainName && isTile(tile, tileName(this.gameNum)));
		}
		if (!this.isPlayableTrain(trainName)) {
			return (false);
		}
		return (tileHasValue(tile, tileNums(this.trainTop(trainName))[1]));
	}
	// add up the point currently in my hand
	pointsInHand () {
		let score = 0;
		this.hand.forEach((tile) => {
			if (tile === tileName(0, 0)) {
				score += 50;
			} else {
				score += tileNums(tile).reduce((t, v) => Number(t) + Number(v));
			}
		});
		return (score);
	}
	// return the train array for trainName
	findTrain (trainName) {
		return (
			(trainName ? this.findPlayer(trainName).train : this.mexTrain)
		);
	}
	// return the tile at the end of the train
	trainTop (trainName) {
		const train = this.findTrain(trainName);
		return (train.length === 0 ? tileName(this.gameNum) : train[0]);
	}
	// add tile to my hand
	addTileToHand (tile) {
		assert(!this.hasTileInHand(tile), `addTileToHand: ${tile} already present`);
		this.hand.push(tile);
		this._checkLayout('addTileToHand');
	}
	// remove tile from my hand
	removeTileFromHand (tile) {
		var index = this.hand.findIndex(t => isTile(tile, t));
		assert(index >= 0, `removeTileFromHand: can't find ${tile} in hand`);
		this.hand.splice(index, 1);
		if (this.drawnTile && isTile(tile, this.drawnTile)) {
			this.drawnTile = '';
		} else {
			index = this.layout.findIndex(t => isTile(tile, t));
			assert(index >= 0, `removeTileFromHand: can't find ${tile} in layout`);
			this.layout[index] = '';
		}
		if (this.selected && isTile(tile, this.selected)) {
			this.selected = '';
		}
		this._checkLayout('removeTileFromHand');
	}
	// move tile to a new position in my layout. tile can be in layout or is drawn tile.
	moveTile (tile, toIndex) {
		let fromIndex = this.layout.indexOf(tile);
		console.log(`moveTile: ${tile} at ${fromIndex} to ${toIndex}`);
		assert(tile, "moveTile: blank tile");
		assert(toIndex < this.layout.length, "moveTile: bad toIndex");
		if (toIndex < 0) {
			if (isTile(tile, this.drawnTile)) {
				// drop on same drawn tile. flip it.
				this.drawnTile = tileFlip(tile);
			}
		} else if (this.layout[toIndex]) {
			// a tile is already there.
			if (fromIndex === toIndex) {
				// drop on same layout tile. flip it.
				this.layout[fromIndex] = tileFlip(tile);
			} else {
				// shift a contiguous group of tiles right to make room
				let endIndex = this.layout.indexOf("", toIndex + 1);
				if (endIndex < 0) {
					endIndex = this.layout.length;
					this.layout.splice(endIndex, 0, ...Array(LAYOUT_COLS).fill(''));
				}
				if (isTile(tile, this.drawnTile)) {
					// from the drawn tile: delete it
					this.drawnTile = '';
				} else if (fromIndex !== toIndex) {
					// from the layout: delete it
					this.layout[fromIndex] = '';
				}
				this.layout.splice(endIndex, 1);
				this.layout.splice(toIndex, 0, tile);
			}
		} else if (fromIndex >= 0) {
			// drag from layout to an empty spot
			this.layout[toIndex] = tile;
			this.layout[fromIndex] = '';
		} else if (isTile(tile, this.drawnTile)) {
			// drag from drawn tile to an empty spot
			this.layout[toIndex] = this.drawnTile;
			this.drawnTile = '';
		}
		this.selected = '';
		this._checkLayout('moveTile');
	}
	// add or remove a layout a row
	layoutRow (row, add) {
		assert(this.layout.length % LAYOUT_COLS === 0
			&& row < this.layout.length / LAYOUT_COLS,
			`layoutRow: bad row argument`);
		if (add) {
			this.layout.splice((row + 1) * LAYOUT_COLS, 0, ...Array(LAYOUT_COLS).fill(''));
		} else {
			let removed = this.layout.splice(row * LAYOUT_COLS, LAYOUT_COLS);
			assert(removed.every(t => !t), `layoutRow: non-mepty removed row`);
		}
	}
	// return the player object for player name
	findPlayer (name) {
		if (!name || !this.players) {
			return (null);
		}
		return (this.players.find(p => (p.name === name)));
	}
	// display msg in a popup. cb is a callback called when done.
	notice (msg, cb) {
		this.messageQ.push({msg:msg, cb:cb});
		if (this.messageQ.length === 1) {
			this.message = msg;
			this.stateNum++;
			this.onChangeFn();
		}
	}
	// the message display is competed
	noticeDone () {
		if (this.messageQ[0].cb) {
			this.messageQ[0].cb();
		}
		this.messageQ.shift();
		this.message = "";
		this.stateNum++;
		this.onChangeFn();
		if (this.messageQ.length) {
			setTimeout(() => {
				this.message = this.messageQ[0].msg;
				this.stateNum++;
				this.onChangeFn();
			}, 500);
		}
	}
	// check for inactivity
	_checkInactive () {
		this.lastGameNum = -1;
		this.lastMoveNum = -1;
		this.lastUpdate = 0;
		this.warned = false;
		setInterval(() => {
			// don't do anything if there's no game
			if (!this.isGame()) {
				this.lastUpdate = 0;
				return;
			}

			// poll for other player's moves, or joining
			if (!this.isMyTurn()) {
				this.getMove('get', true);
			} else if (this.players.some(p => !p.joined)) {
				this.getMove('getJoined', true);
			}

			// check for inactivity
			let now = (new Date()).getTime();
			this.lastUpdate = this.lastUpdate || now;
			if (this.gameNum === this.lastGameNum
				&& this.moveNum === this.lastMoveNum
			) {
				// inactive. Reset if too long or warn.
				let idle = now - this.lastUpdate;
				if (idle >= GAME_TIMEOUT) {
					// Timeout End the game after giving notice...
					console.log(`game: inactive game. restarting`);
					this.notice("Inactive game. Restarting...");
					setTimeout(() => window.location.reload(), 3000);
				} else if (idle >= GAME_TIMEOUT / 2 && !this.warned) {
					// Inactive game. Warn
					console.log(`game: inactive game`);
					this.notice(
						`Warning: This game will automatically end after `
							+`${Math.ceil(GAME_TIMEOUT / 60000)} minutes `
							+`of inactivity.`
					);
					this.warned = true;
				}
			} else {
				this.lastUpdate = now;
				this.warned = false;
				this.lastGameNum = this.gameNum;
				this.lastMoveNum = this.moveNum;
			}
		}, MOVE_POLL_INTERVAL);
	}
	// returns true if my layout/drawn tile is consistent with my hand
	_checkLayout (origin) {
		assert(this.hand.every(tile => tile), `${origin}: empty tile in hand`);
		assert(this.hand.every(tile =>
				this.layout.some(t => isTile(t, tile)) ||isTile(this.drawnTile, tile)
			),
			`${origin}: missing tiles in layout`
		);
		assert(this.layout.every(tile =>
				!tile || this.hand.some(t => isTile(t, tile))
			),
			`${origin}: too many tiles in layout`
		);
		assert(!this.drawnTile || this.hand.some(t => isTile(t, this.drawnTile)),
			`${origin}: drawnTile missing from hand`);
		assert(
			!this.selected || this.layout.some(t => isTile(t, this.selected))
				|| isTile(this.selected, this.drawnTile) ,
			`${origin}: bad selected tile`
		);
	}

	/*
	 * Test and local mode
	 */
	// in local mode, change to the next player
	showNextPlayer () {
		if (!test || !local) {
			return;
		}
		this._playerCache.set(this.playerName, {
			playerName: this.playerName,
			playerIndex: this.playerIndex,
			curPlayerIndex: this.curPlayerIndex,
			gameId: this.gameId,
			gameNum: this.gameNum,
			moveNum: this.moveNum,
			turnMove: this.turnMove,
			players: this.players,
			mexTrain: this.mexTrain,
			hand: this.hand,
			layout: this.layout,
			drawnTile: this.drawnTile,
			lastDrawnTile: this.lastDrawnTile,
			selected: this.selected,
			message: this.message,
			messageQ: this.messageQ,
		});
		let playerNames = [...this._playerCache.keys()];
		let nextPlayer = playerNames[(this.playerIndex + 1) % playerNames.length];
		Object.assign(this, this._playerCache.get(nextPlayer));
		this.playerName = nextPlayer;
		console.log(`showNextPlayer: switching to ${this.playerName}, moveNum:${this.moveNum}`);
		if (this.isGame()) {
			this.getMove();
		}
		if (this.isMyTurn()) {
			// sound.play('yourTurn');
		}
		this.stateNum++;
		this.onChangeFn();
	}
	// in test mode, start autoplay
	setAutoPlay () {
		let autoPlay = (() => {
			if (this.message) {
				this.noticeDone();
			}
			if (!this.isMyTurn()) {
				return;
			}
			if (!this.hasInitialTile && this.hasTileInHand(tileName(this.gameNum))) {
				assert(this.playTile(tileName(this.gameNum), ''),
					`setAutoPlay: can't play initial tile'`);
				return;
			}
			let tile = this.layout.find(tile => this.isPlayableTile(tile));
			if (tile) {
				if (this.canPlayTileOnTrain(tile, this.playerName)) {
					// play on player's train
					this.playTile(tile, this.playerName);
				} else if (this.canPlayTileOnTrain(tile, '')) {
					// play on mexican train
					this.playTile(tile, '');
				} else {
					// play on any other available train
					this.players.some(player => {
						if (this.canPlayTileOnTrain(tile, player.name)) {
							this.playTile(tile, player.name);
							return (true);
						}
						return (false);
					});
				}
			} else if (this.boneyardSize > 0){
				this.drawTile();
				let index = this.layout.indexOf('');
				this.moveTile(this.drawnTile, index >= 0 ? index : this.layout.length - 1);
			}

		});
		setInterval(autoPlay, 1000);
	}
}
