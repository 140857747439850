/*eslint semi: ["error", "always"]*/

/*
 * Sounds
 */
import trainAudio from './sound/train whistle.mp3';
import dropAudio from './sound/pop.mp3';
import failAudio from './sound/sorry.mp3';
import knockAudio from './sound/knock.mp3';
import tadaAudio from './sound/tada.mp3';
import allAboardAudio from './sound/all aboard.mp3';
import gameWinnerAudio from './sound/o canada.mp3';
import doubleAudio from './sound/crossing bell.mp3';

/*
 * Complain if the assertion is not true.
 */
export function assert (a, s) {
	console.assert(a, "Assertion failure: " + s);
	if (!a) {
		if (!assert.noAlert) {
			alert("Assertion failure: " + s);
		}
	}
}
assert.noAlert = false;

/*
 * Play sounds or speak text to speech phrases.
 */
export let sound = {
	play: function (id) {
		sound._queue.push(id);
		if (sound._queue.length === 1) {
			sound._next();
		}
	},
	_sounds: {
		train: {
			audio: new Audio(trainAudio),
			time: 2000,
		},
		drop: {
			audio: new Audio(dropAudio),
			time: 500,
		},
		fail: {
			audio: new Audio(failAudio),
			time: 1500,
		},
		chap: {
			audio: new Audio(knockAudio),
			time: 1200,
		},
		uncoveredDouble: {
			audio: new Audio(doubleAudio),
			time: 1200,
		},
		roundWinner: {
			audio: new Audio(tadaAudio),
			time: 2000,
		},
		newTrain: {
			audio: new Audio(allAboardAudio),
			time: 2000,
		},
		gameWinner: {
			audio: new Audio(gameWinnerAudio),
			time: 28000,
		},
		welcome: {
			audio: new SpeechSynthesisUtterance("Welcome to Canadian train, A?"),
			time: 3500,
		},
		yourTurn: {
			audio: new SpeechSynthesisUtterance("Your turn"),
			time: 2000,
		},
	},
	_queue: [],
	_next: function () {
		if (sound._queue.length) {
			let id = sound._queue[0];
			let s = sound._sounds[id];
			if (s.audio instanceof Audio) {
				s.audio.play().catch(() => null);
			} else {
				try {
					speechSynthesis.speak(s.audio);
				} catch (e) {}
			}
			setTimeout(() => {sound._queue.shift(); sound._next();}, s.time);
		}
	},
};

/*
 * Return a tile name of the form /\d+-\d+/ from two tile values.
 * If num2 is omitted, creates a double tile from num1.
 */
export function tileName (num1, num2 = num1) {
	return (String(num1)+'-'+String(num2));
};

/*
 * Return a an array of tile values.
 */
export function tileNums (name) {
	console.assert(name.length > 0, `tileNums: invalid argument`);
	return (name.split('-'));
};

/*
 * Return a tile with the values flipped.
 */
export function tileFlip (tile) {
	return (tileName(...tileNums(tile).reverse()));
};

/*
 * Return true if the t1 and t2 are the same tile (though perhaps flipped).
 */
export function isTile (t1, t2) {
	return (t1 && t2 && (t1 === t2 || t1 === tileFlip(t2)));
};

/*
 * Return true if one of the tile numbers == value.
 */
export function tileHasValue (tile, value) {
	const values = tileNums(tile);
	return (values[0] === String(value) || values[1] === String(value));
};

/*
 * Return true if the tile is a double tile.
 */
export function isDoubleTile (tile) {
	const values = tileNums(tile);
	return (values[0] === values[1]);
};
